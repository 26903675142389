<template>
  <div class="newsList">
    <div class="swiper">
      <el-carousel height="1.40rem">
        <el-carousel-item v-for="item in imgList" :key="item">
          <img @click="goXSY()" class="img" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="centerTitle">
        生涯资讯
      </div> -->
    </div>
    <!-- 富文本内容 -->
    <div class="mainRich">
      <p class="title">{{ news.data.title }}</p>
      <div class="left">
        <div class="time">{{ news.data.issue_time.slice(0, 10) }}</div>
        <div class="content">
          <div v-html="news.data.content"></div>
          <!-- <p v-text="123123"></p> -->
        </div>
      </div>
      <div class="right">
        <div class="return" @click="back()">返回</div>
        <div>
          <little-message></little-message>
        </div>
      </div>
    </div>
  </div>
  <div class="message">
    <message></message>
  </div>
</template>
<script lang="ts">
import axios from "@/utils/axios";
import { defineComponent, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import Message from "@/components/message/Message.vue";
import littleMessage from "./modules/littleMessage.vue";

export default defineComponent({
  name: "swiper",
  components: {
    Message,
    littleMessage,
  },
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/生涯资讯/生涯资讯.jpg"),
      ],
      // 
    };
  },
  methods: {
    goXSY() {
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      // window.open("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard")
    },
    back(){
      this.$router.go(-1)
    }
  },
  setup() {
    // const router = useRouter();
    var imgList = document.querySelectorAll("img");
    // console.log(imgList, "imggggggggggggggg");
    const route = useRoute();
    const news = reactive({ data: "" });
    // console.log(router,'1111111',route);
    // console.log(route,"route");
    console.log(route.params, "route");
    axios
      .get(
        "https://www.xsy985.com/api/news/news/" + route.params.id + "/read/"
      )
      .then(function (response) {
        console.log(response.data, "news");
        const regex = new RegExp("<img", "gi");
        response.data.content = response.data.content.replace(
          regex,
          `<img style="max-width: 100%; height: auto"`
        );

        news.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return {
      news,
    };
  },
});
</script>
<style lang='scss' scoped>
.newsList {
  background-color: #fff;
  .swiper {
    width: 1920px;
    height: 280px;
    margin-left: -360px;
    position: relative;
    // cursor: pointer;
    .img {
      width: 1920px;
      height: 280px;
    }
  }
  // .centerTitle{
  //   position: absolute;
  //   top: 20px;
  //   left: 100px;
  //   font-size: 44px;
  //   color:#fff;
  //   z-index: 99;
  // }

  // .centerSubTitle{
  //   position: absolute;
  //   top: 20px;
  //   left: 100px;
  //   font-size: 14px;
  // }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 497px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  // 富文本开始
  .mainRich {
    float: left;
    overflow: hidden;
    min-height: 900px;
    box-sizing: border-box;
    padding-bottom: 100px;
    background-color: #fff;
    .title {
      color: #00a5a8;
      font-size: 30px;
      margin-bottom: 60px;
      width: 800px;
      line-height: 36px;
    }
    // 左侧富文本
    .left {
      float: left;
      .time {
        color: #99a9bf;
        font-size: 12px;
        width: 800px;
        border-bottom: 2px solid #00a5a8;
        padding-bottom: 10px;
      }
      .content {
        width: 800px;
        border-right: 2px solid #00a5a8;
        padding-left: 40px;
        padding-top: 30px;
        min-height: 100px;
        img {
          max-width: 700px;
        }
        span {
          img {
            max-width: 700px;
          }
        }
      }
    }
    // 右侧推广
    .right {
      float: left;
      margin-left: 40px;
      width: 310px;
      .return {
        width: 310px;
        display: inline-block;
        text-align: right;
        font-size: 22px;
        color: #99a9bf;
        cursor: pointer;
      }
    }
  }
}
</style>
