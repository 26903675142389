
import axios from "@/utils/axios";
import { defineComponent, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import Message from "@/components/message/Message.vue";
import littleMessage from "./modules/littleMessage.vue";

export default defineComponent({
  name: "swiper",
  components: {
    Message,
    littleMessage,
  },
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/生涯资讯/生涯资讯.jpg"),
      ],
      // 
    };
  },
  methods: {
    goXSY() {
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      // window.open("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard")
    },
    back(){
      this.$router.go(-1)
    }
  },
  setup() {
    // const router = useRouter();
    var imgList = document.querySelectorAll("img");
    // console.log(imgList, "imggggggggggggggg");
    const route = useRoute();
    const news = reactive({ data: "" });
    // console.log(router,'1111111',route);
    // console.log(route,"route");
    console.log(route.params, "route");
    axios
      .get(
        "https://www.xsy985.com/api/news/news/" + route.params.id + "/read/"
      )
      .then(function (response) {
        console.log(response.data, "news");
        const regex = new RegExp("<img", "gi");
        response.data.content = response.data.content.replace(
          regex,
          `<img style="max-width: 100%; height: auto"`
        );

        news.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return {
      news,
    };
  },
});
